var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"topic-post-detail-container",class:{
    forbidden: _vm.forbidden,
  }},[_c('div',{staticClass:"topic-post-detail__header",class:{
      'skeleton-loading-wrapper': _vm.loading,
    }},[_c('div',{staticClass:"header__row"},[_c('p',[_vm._v(_vm._s(_vm.groupID > 0 ? "小组" : "亲友"))]),_c('p',[_vm._v("主题")])]),_c('div',{staticClass:"header__row",class:{
        'skeleton-item': _vm.loading,
      }},[(_vm.groupID > 0)?_c('p',{on:{"click":function($event){$event.stopPropagation();return _vm.goBackGroup.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.group ? _vm.group.title : "")+" ")]):_c('p',{on:{"click":function($event){$event.stopPropagation();return _vm.userJump(_vm.topicAuthor.uid)}}},[_vm._v(" "+_vm._s(_vm._f("comment")(_vm.topicAuthor.nickname,_vm.topicAuthor.uid))+" ")]),_c('p',{attrs:{"tag":"p","to":{
          path:
            _vm.role === 'user'
              ? `/topic/${_vm.topicInfo.id}`
              : `/friend/${_vm.topicAuthor.uid}/topics/${_vm.topicInfo.id}`,
        }},on:{"click":function($event){$event.stopPropagation();return _vm.goTopics.apply(null, arguments)}}},[_vm._v(" # "+_vm._s(_vm.topicInfo.title)+" ")])])]),_c('div',{staticClass:"topic-list-wrapper"},[_c('div',{staticClass:"list-wrapper__inner"},_vm._l((_vm.lists),function(item,index){return _c('timeline-item',{key:index,attrs:{"theme":"topic","info":item,"queue":index,"lock-all":_vm.role === 'user' || _vm.userLockAll,"call-post-del":_vm.handlerPostDelete,"is-stranger":false,"groupMangers":_vm.groupMangers,"renderType":_vm.renderType},on:{"postDel":_vm.onPostDel,"postReply":_vm.onPostReply}})}),1)]),_c('reply-form',{attrs:{"theme":"topic","post-link":_vm.replyPostLink,"ex-info":_vm.exInfo},on:{"afterReply":_vm.onAfterReply,"cancel":_vm.onReplyFormCancel}}),_c('slogan',{attrs:{"text":"没有人是一座孤岛"}}),(_vm.isFromShare)?_c('share-bottom'):_c('div',{staticClass:"group__footer-fixed-nav"},[_c('share-bottom')],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }