<template>
  <div
    class="topic-post-detail-container"
    :class="{
      forbidden,
    }"
  >
    <div
      class="topic-post-detail__header"
      :class="{
        'skeleton-loading-wrapper': loading,
      }"
    >
      <div class="header__row">
        <p>{{ groupID > 0 ? "小组" : "亲友" }}</p>
        <p>主题</p>
      </div>
      <div
        class="header__row"
        :class="{
          'skeleton-item': loading,
        }"
      >
        <p v-if="groupID > 0" @click.stop="goBackGroup">
          {{ group ? group.title : "" }}
        </p>
        <p @click.stop="userJump(topicAuthor.uid)" v-else>
          {{ topicAuthor.nickname | comment(topicAuthor.uid) }}
        </p>
        <p
          tag="p"
          :to="{
            path:
              role === 'user'
                ? `/topic/${topicInfo.id}`
                : `/friend/${topicAuthor.uid}/topics/${topicInfo.id}`,
          }"
          @click.stop="goTopics"
        >
          # {{ topicInfo.title }}
        </p>
      </div>
    </div>
    <div class="topic-list-wrapper">
      <div class="list-wrapper__inner">
        <timeline-item
          v-for="(item, index) in lists"
          theme="topic"
          :key="index"
          :info="item"
          :queue="index"
          :lock-all="role === 'user' || userLockAll"
          :call-post-del="handlerPostDelete"
          :is-stranger="false"
          :groupMangers="groupMangers"
          :renderType="renderType"
          @postDel="onPostDel"
          @postReply="onPostReply"
        ></timeline-item>
      </div>
    </div>
    <!-- 回复表单 -->
    <reply-form
      theme="topic"
      @afterReply="onAfterReply"
      @cancel="onReplyFormCancel"
      :post-link="replyPostLink"
      :ex-info="exInfo"
    ></reply-form>
    <!-- 标语 -->
    <slogan text="没有人是一座孤岛"></slogan>
    <!-- 底部按钮 -->
    <share-bottom v-if="isFromShare"></share-bottom>
    <div class="group__footer-fixed-nav" v-else>
      <!-- <div @click="goBack(topicid)" class="nav__item">
        <strong>返回</strong>
      </div> -->
      <share-bottom></share-bottom>
    </div>
  </div>
</template>

<script>
import topicPostMixin from '@/mixins/topic-post';
import forbiddenMixin from '@/mixins/forbidden';
import { mapState, mapActions } from 'vuex';
import wxtool from '@/utils/wx-tool';
import slogan from '../common/_slogan.vue';
import timelineItem from '../timeline/_item.vue';
import replyForm from '../common/_reply-form.vue';
import shareBottom from '../common/_share-bottom.vue';

export default {
  name: 'page-topic-post-detail',
  props: ['postid'],
  mixins: [topicPostMixin, forbiddenMixin],
  components: {
    timelineItem,
    replyForm,
    slogan,
    shareBottom,
  },
  data() {
    return {
      role: '',
      topicid: 0,
      detail: null,
      group: null,
    };
  },
  computed: {
    ...mapState(['user', 'myGroups']),
    loading() {
      return this.detail === null;
    },
    topicAuthor() {
      return this.detail ? this.detail.author : {};
    },
    topicInfo() {
      return this.detail ? this.detail.topic : {};
    },
    shareInit() {
      if (this.groupID > 0) {
        return this.user && this.detail && this.group;
      }
      return this.user && this.detail;
    },
    linkSource() {
      return this.$route.query.from || '';
    },
    isFromShare() {
      return this.linkSource === 'wechat';
    },
  },
  watch: {
    $route: {
      handler() {
        this.pageInit();
      },
      immediate: true,
    },
    user(val) {
      const that = this;
      if (that.lists.length > 0) {
        that.role = val.uid === that.lists['0'].topic.author_uid ? 'user' : 'friend';
      } else {
        that.role = 'friend';
      }
      this.hasViewd();
    },
    shareInit(val) {
      const that = this;
      if (val) {
        // 分享文案
        const title = `${that.user.nickname} 和你分享了${
          that.groupID > 0 ? '小组内容' : `话题 ${that.detail.topic.title}`
        }`;
        const desc = that.groupID > 0 ? `来自小组：${that.group.title}` : '建设你 建设我 建设我们共同的时光';
        const link = that.groupID > 0
          ? `${process.env.VUE_APP_BASE}group/${that.groupID}/topic/post/${that.postid}`
          : `${process.env.VUE_APP_BASE}topic/post/${that.postid}`;
        const imgUrl = that.groupID > 0
          ? that.$root.$options.filters.parseImage(that.group.cover)
          : that.detail.author.headimgurl;
        wxtool.share(
          {
            title,
            desc,
            link,
            imgUrl,
            tuid: that.topicInfo.author_uid || 0,
          },
          this.$route.fullPath,
        );
      }
    },
  },
  mounted() {
    const that = this;
    if (that.groupID) {
      that.getGroup(that.groupID).then(() => {
        that.group = that.myGroups.get(Number(that.groupID));
      });
    }
  },
  methods: {
    ...mapActions(['getGroup']),
    hasViewd() {
      const that = this;
      that.viewTopicPosts(that.topicid, [that.postid], that.user.uid);
    },
    pageInit() {
      this.getPostDetail();
      // this.hasViewd();
    },
    getPostDetail() {
      const that = this;
      that.$request
        .get(`api/user/topic/post/detail/${that.postid}`)
        .then((res) => {
          const { data } = res;
          that.role = data.author_uid === that.user ? 'user' : 'friend';
          that.topicid = data.topic_id;
          that.detail = data;
          that.lists.push(that.detail);
        })
        .catch((err) => {
          let url = err.data ? `/friend/${err.data.author_uid}` : '/';
          const resStatus = err.status;
          if (resStatus === 403 || resStatus === 405) {
            let text = '查看发布人';
            if (resStatus === 405) {
              text = '解锁话题';
              url = that.groupID
                ? `/group/${that.groupID}/topic/${that.topicInfo.id}`
                : `/friend/${err.data.author_uid}/topics/${err.data.topic_id}`;
            }
            this.notifyForbidden('topic', err.message, text, url);
          }
          if (err.status === 404) {
            const msg = url === '/' ? '该内容已消失' : '该内容已消失<br/>请查看发布人其他内容';
            this.notifyNotFound(msg, url);
          }
        });
    },
    goTopics() {
      const {
        topicInfo, groupID, topicAuthor, role,
      } = this;

      let path = '';

      if (groupID > 0) {
        path = `/group/${groupID}/topic/${topicInfo.id}`;
      } else if (role === 'user') {
        path = `/topic/${topicInfo.id}`;
      } else {
        path = `/friend/${topicAuthor.uid}/topics/${topicInfo.id}`;
      }
      this.$router.replace({
        path,
      });
    },
    goBackGroup() {
      const path = this.linkSource
        ? `/group/${this.groupID}?from=${this.linkSource}`
        : `/group/${this.groupID}`;
      this.$router.replace({
        path,
      });
    },
  },
};
</script>

<style></style>
